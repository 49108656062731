<template>
  <v-app>
    <v-navigation-drawer
      stateless
      v-model="drawer"
      app
      clipped
      width="500"
      v-if="(isUserLoggedIn || isAuthDisabled) && $route.name !== 'Login' && mapLoaded"
    >
      <sites></sites>
    </v-navigation-drawer>
    <v-app-bar app clipped-left color="primary" dark>
      <v-app-bar-nav-icon
        v-if="(isUserLoggedIn || isAuthDisabled) && $route.name !== 'Login'"
        @click="menuButton"
      >
        <v-icon v-if="$route.name === 'Draw' || $route.name === 'Home'">mdi-menu</v-icon>
        <v-icon v-else>mdi-arrow-left</v-icon>
      </v-app-bar-nav-icon>
      <img
        :src="
          `${publicPath}images/${
            config.getEnv(config.enum.VUE_APP_BRANDING) === 'SENSODOC' ? 'sensodoc' : 'gli'
          }_logo.png`
        "
        class="gliLogoImage"
      />
      <h1 class="title">
        {{
          $t(
            config.getEnv(config.enum.VUE_APP_BRANDING) === 'SENSODOC'
              ? 'SensoDoc Floorplan Editor'
              : 'GLI Floorplan Editor'
          )
        }}
      </h1>
      <v-spacer />
      <LanguageSelector />
      <v-menu offset-y v-if="isUserLoggedIn && !isAuthDisabled">
        <template v-slot:activator="{ on }">
          <v-btn dark icon v-on="on">
            <v-icon>mdi-cog</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item>
            <v-list-item-title class="font-weight-bold">{{ displayName }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="isAdmin && !isSuperAdmin" @click="editOrganization">
            <v-list-item-title>{{ $t('Settings') }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="isAdmin || isEditor || isOperator" link to="/venues">
            <v-list-item-title>{{ $t('Venues') }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="isSuperAdmin" link to="/organizations">
            <v-list-item-title>{{ $t('Organizations') }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="isAdmin" link to="/users">
            <v-list-item-title>{{ $t('Users') }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-title>{{ $t('Logout') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>

    <v-footer app dark color="primary">
      <status-bar v-if="isUserLoggedIn"></status-bar>
    </v-footer>

    <v-snackbar
      v-if="!!alert.message"
      :color="alert.type"
      :value="!!alert.message"
      @input="
        (value) => {
          if (!value) {
            clearAlert();
          }
        }
      "
      :timeout="alert.type === 'success' ? 2000 : -1"
    >
      {{ alert.message }}
      <template slot="action"
        ><v-btn fab x-small text @click="clearAlert"><v-icon>mdi-close</v-icon></v-btn></template
      >
    </v-snackbar>
    <organization-dialog v-if="editedOrganization && !organizationPageLoaded"></organization-dialog>
    <v-overlay
      style="z-index:1000"
      class="text-center"
      absolute="absolute"
      :value="isRestoring"
      opacity="0.8"
    >
      <v-progress-circular indeterminate size="64"></v-progress-circular>
      <div class="mt-4">{{ $t('Restoring') }}...</div>
    </v-overlay>
  </v-app>
</template>

<style lang="scss">
.v-application {
  main,
  .map {
    flex-grow: 1;
    width: 100%;
    height: 100%;
  }

  #map {
    width: 100%;
    height: 100%;
  }

  .mapboxgl-popup-content {
    padding: 0;
  }

  .v-data-table table:first-child {
    table-layout: fixed;

    td {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .gli-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  footer {
    z-index: 4;
    height: 32px;
  }
}
</style>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import LanguageSelector from './components/LanguageSelector';
import StatusBar from './components/StatusBar';
import config from './helpers/configProvider';

export default {
  name: 'app',

  data() {
    return {
      drawer: false,
      lastSiteRoute: undefined,
      publicPath: process.env.BASE_URL,
      homeRoute: { name: 'Home', path: '/' },
      config
    };
  },

  mounted() {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.head.appendChild(link);
    }
    link.href =
      config.getEnv(config.enum.VUE_APP_BRANDING) === 'SENSODOC'
        ? 'images/sensodoc_favicon.png'
        : 'images/gli_favicon.png';
  },

  computed: {
    ...mapState({
      alert: (state) => state.alert,
      isAuthDisabled: (state) => state.account.authDisabled
    }),
    ...mapGetters('account', [
      'isUserLoggedIn',
      'isAdmin',
      'displayName',
      'isSuperAdmin',
      'isEditor',
      'isOperator'
    ]),
    ...mapGetters('status', ['mapLoaded']),
    ...mapGetters('organization', ['editedOrganization']),
    ...mapGetters('organization', { organizationPageLoaded: 'pageLoaded' }),
    ...mapState('app', ['isRestoring'])
  },

  methods: {
    ...mapActions({
      clearAlert: 'alert/clear',
      logout: 'account/logout',
      editOrganization: 'organization/edit'
    }),

    menuButton() {
      if (this.$route.name === 'Draw' || this.$route.name === 'Home') {
        this.drawer = !this.drawer;
      } else {
        if (this.lastSiteRoute) {
          this.$router.push(this.lastSiteRoute);
        } else {
          this.$router.push(this.homeRoute);
        }
      }
    }
  },

  watch: {
    $route: function(to, from) {
      if (from.name === 'Draw') {
        this.lastSiteRoute = from;
      }
      if (to !== from && to.name === 'Home') {
        this.drawer = true;
      } else {
        this.drawer = false;
      }
    }
  },
  components: {
    LanguageSelector,
    StatusBar,
    Sites: () => import('./components/Sites.vue'),
    OrganizationDialog: () => import('./components/OrganizationDialog.vue')
  }
};
</script>
