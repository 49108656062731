import { Endpoints, validateEdges } from '@/helpers/validateGeometry';
import { jsonToZip } from '@/helpers/zip';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { getField, updateField } from 'vuex-map-fields';
import constants from '~/shared/constants';
import { isNodeOrJunction } from '~/shared/utils';
import confirm from '../helpers/confirm';
import i18n from '../i18n';
import { router } from '../router';
import ApiService from '../services/ApiService';
import { Site, SiteInVuex } from '../types/types';

type SiteBackup = {
  zip?: File;
};

type SiteState = {
  sites: SiteInVuex[];
  isLoading: boolean;
  newSite?: SiteInVuex;
  editedSite?: SiteInVuex;
  isNew: boolean;
  hasInvalidGeometry: boolean;
  siteToRestore?: string;
  backupFileToRestore?: SiteBackup;
  hasRestoreError: boolean;
};

// these fields need to exist to become reactive
const emptySite: SiteInVuex = {
  id: undefined,
  name: undefined,
  organizationId: undefined
};

const state: SiteState = {
  sites: [],
  isLoading: false,
  newSite: undefined,
  editedSite: undefined,

  isNew: false,
  hasInvalidGeometry: false,
  siteToRestore: undefined,
  backupFileToRestore: undefined,
  hasRestoreError: false
};

const actions: ActionTree<SiteState, any> = {
  async getAll({ commit }) {
    commit('isLoading');
    const { data } = await ApiService.getSites();
    commit('getAll', data);
  },

  addNew({ commit }) {
    commit('addNew');
  },

  async edit({ commit, dispatch }, { siteId, mainMode }: { siteId: string; mainMode?: string }) {
    const { data } = await ApiService.getSite(siteId);
    dispatch('history/clear', null, { root: true });
    commit('level/reset', null, { root: true });
    commit('level/refresh', data.levels, { root: true });
    dispatch(
      'feature/refresh',
      { features: data.features, levels: data.levels, exchangePoints: data.exchangePoints },
      { root: true }
    );
    dispatch('image/refresh', data.images, { root: true });
    dispatch('customImage/refresh', data.customImages, { root: true });
    dispatch('status/setCurrentMainMode', mainMode ?? constants.MAIN_MODES.STATIC, { root: true });

    delete data.levels;
    delete data.features;
    delete data.exchangePoints;
    delete data.images;
    delete data.customImages;

    commit('edit', data);
    dispatch('history/add', null, { root: true });
  },

  async saveNew({ commit, state, dispatch }) {
    const levels = [
      { name: 'second floor', id: 2, isStreetLevel: false },
      { name: 'first floor', id: 1, isStreetLevel: false },
      { name: 'ground floor', id: 0, isStreetLevel: true }
    ];
    const formData = new FormData();
    const site: Site = {
      ...state.newSite,
      levels: [...levels.reverse()],
      features: [],
      exchangePoints: [],
      images: [],
      customImages: []
    };
    formData.append('site', JSON.stringify(site));

    await ApiService.saveSite(formData);
    commit('save');
    dispatch('alert/success', null, { root: true });
    dispatch('getAll');
  },

  async save({ commit, state, dispatch, rootGetters }) {
    // prettier-ignore
    try {
      const levels = rootGetters['level/levels'];

      const featuresByLevel = rootGetters['feature/featuresByLevel'];

      let features = [];
      for (const levelId in featuresByLevel) {
        features.push(...featuresByLevel[levelId]);
      }
      features = features.map((feature) => ({
        ...feature.feature,
        id: feature.id,
        levelId: feature.levelId
      }));

      // validate that each wall ends on existing node
      const lineStrings = features.filter(feature => 
        feature.geometry.type === 'LineString' && 
        feature.properties._type && 
        feature.properties._type === constants.FEATURE_TYPES.EDGE
      );
      const points = features.filter(feature => 
        feature.geometry.type === 'Point' && 
        feature.properties._type && 
        feature.properties._type === constants.FEATURE_TYPES.NODE
      );
      const isValid = validateEdges(
        lineStrings.map(
          lineString => ({from: lineString.properties.fromNode, to: lineString.properties.toNode})
        ) as Endpoints[],
        [...new Set(points.map(point => point.id))] as string[]
      );
      if(!isValid){
        commit('setHasInvalidGeometry', !isValid);
        return;
      }

      for (const feature of features) {
        if (!isNodeOrJunction(feature)) {
          feature.id = feature.properties.alreadyCreated ? feature.id : undefined;
        }
        delete feature.properties.alreadyCreated;
      }

      const imagesByLevel = rootGetters['image/imagesByLevel'];
      const images = [];
      const formData = new FormData();
      for (const imageLevelId in imagesByLevel) {
        const image = imagesByLevel[imageLevelId];
        // base64 dataUrl to file
        const file = await fetch(`data:${image.meta.mimeType};base64,${image.base64}`)
          .then((res) => res.blob())
          .then((blob) => new File([blob], `${imageLevelId}`, { type: image.meta.mimeType }));
        formData.append('files', file);
        images.push(image.meta);
      }

      const customImagesByLevel = rootGetters['customImage/imagesByLevel'];
      const customImages = [];
      for (const imageLevelId in customImagesByLevel) {
        for (const customImage of customImagesByLevel[imageLevelId]) {
          // base64 dataUrl to file
          const file = await fetch(`data:${customImage.meta.mimeType};base64,${customImage.base64}`)
            .then((res) => res.blob())
            .then(
              (blob) =>
                new File([blob], `${imageLevelId}+${customImage.meta.id}`, {
                  type: customImage.meta.mimeType
                })
            );
          formData.append('files', file);
          customImages.push(customImage.meta);
        }
      }

      const site = {
        ...state.editedSite,
        levels: [...levels].reverse(),
        features: features,
        exchangePoints: rootGetters['feature/exchangePoints'],
        images: images,
        customImages: customImages
      };
      formData.append('site', JSON.stringify(site));

      await ApiService.saveSite(formData);
      commit('save');
      dispatch('history/reset', null, { root: true });
      dispatch('alert/success', null, { root: true });
      dispatch('getAll');
      if (state.editedSite && state.editedSite.id) {
        const { data } = await ApiService.getSite(state.editedSite.id);
        dispatch(
          'feature/refresh',
          { features: data.features, levels: data.levels, exchangePoints: data.exchangePoints },
          { root: true }
        );
        dispatch('level/refresh', data.levels, { root: true });
      }
    } catch (error: any) {
      if (
        error.response?.data?.message &&
        error.response.data.message === 'Transaction too large!'
      ) {
        dispatch(
          'alert/error',
          i18n.t(
            // eslint-disable-next-line max-len
            'Saving unsuccessful! Total image size is over 16 MB. Delete some images, or reduce their sizes!'
          ),
          { root: true }
        );
      }
    }
  },

  async delete({ commit, dispatch }, siteId) {
    const answer = await confirm(i18n.t('Are you sure you want to delete this site?'), {
      title: i18n.t('Delete site'),
      buttonTrueText: i18n.t('Delete'),
      buttonFalseText: i18n.t('Cancel')
    });
    if (answer) {
      await ApiService.deleteSite(siteId);

      if (router.currentRoute.params.siteId === siteId) {
        commit('delete');
        dispatch('level/reset', null, { root: true });
        dispatch('feature/reset', null, { root: true });
        dispatch('image/reset', null, { root: true });
        dispatch('customImage/reset', null, { root: true });
        dispatch('routing/reset', null, { root: true });
        dispatch('history/resetToEmpty', null, { root: true });
        dispatch('alert/success', null, { root: true });
        router.push('/');
      }
      dispatch('getAll');
    }
  },

  async duplicate({ commit }, siteId) {
    await ApiService.duplicateSite(siteId);
    const { data } = await ApiService.getSites();
    commit('getAll', data);
  },

  cancel({ commit }) {
    commit('cancel');
  },
  refreshEditedSiteStyles({ commit }, styles) {
    commit('refreshEditedSiteStyles', styles);
  },

  setHasInvalidGeometry({ commit }, isInvalid) {
    commit('setHasInvalidGeometry', isInvalid);
  },

  async backupSite(__, site) {
    const zipJson = await ApiService.backupSite(site.id);
    jsonToZip(site.name, zipJson.data);
  },

  startRestore({ commit }, siteId) {
    commit('startRestore', siteId);
  },
  cancelRestore({ commit, dispatch }) {
    commit('cancelRestore');
    dispatch('app/setRestoring', false, { root: true });
  },
  async executeRestore({ commit, dispatch, state }) {
    if (state.siteToRestore && state.backupFileToRestore?.zip) {
      commit('setHasRestoreError', false);
      dispatch('app/setRestoring', true, { root: true });
      const response = await ApiService.restoreSite(
        state.siteToRestore,
        state.backupFileToRestore.zip
      );
      if (response.status !== 200) {
        commit('setHasRestoreError', true);
        dispatch('app/setRestoring', false, { root: true });
        return false;
      }
      return true;
    }
    return false;
  }
};

const mutations: MutationTree<SiteState> = {
  getAll(state, sites) {
    state.sites = sites;
    state.isLoading = false;
  },

  isLoading(state) {
    state.isLoading = true;
  },

  addNew(state) {
    state.newSite = { ...emptySite };
    state.isNew = true;
  },

  edit(state, payload) {
    state.editedSite = { ...emptySite, ...payload };
    state.isNew = false;
    state.hasInvalidGeometry = false;
  },

  save(state) {
    state.isNew = false;
    state.hasInvalidGeometry = false;
  },

  loadFromHistory(state, payload) {
    state.editedSite!.name = payload;
  },

  delete(state) {
    state.editedSite = undefined;
    state.isNew = false;
  },

  cancel(state) {
    state.editedSite = undefined;
    state.isNew = false;
    state.hasInvalidGeometry = false;
  },
  refreshEditedSiteStyles(state, payload) {
    state.editedSite = { ...state.editedSite, styles: payload };
  },

  setHasInvalidGeometry(state, isInvalid) {
    state.hasInvalidGeometry = isInvalid;
  },

  startRestore(state, siteId) {
    state.hasRestoreError = false;
    state.siteToRestore = siteId;
    state.backupFileToRestore = {};
  },
  cancelRestore(state) {
    state.hasRestoreError = false;
    state.siteToRestore = undefined;
    state.backupFileToRestore = undefined;
  },
  setHasRestoreError(state, hasRestoreError) {
    state.hasRestoreError = hasRestoreError;
  },

  updateField
};

const getters: GetterTree<SiteState, any> = {
  sites: (state) => state.sites,
  editedSite: (state) => state.editedSite,
  newSite: (state) => state.newSite,
  isNew: (state) => state.isNew,
  hasInvalidGeometry: (state) => state.hasInvalidGeometry,
  isLoading: (state) => state.isLoading,
  getField
};

export const site = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
