import { zipToJson } from '@/helpers/zip';
import { Site } from '@/types/types';
import Api from './AxiosInstance';
const PATH = '/api/sites';

export default {
  getSites() {
    return Api.get<Site[]>(PATH);
  },

  getSite(id: string) {
    return Api.get<Site>(PATH + `/${id}`);
  },

  saveSite(formData: any) {
    return Api.post(PATH, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },

  deleteSite(id: string) {
    return Api.delete(PATH + `/${id}`);
  },

  exportLevel({ siteId, levelId, mode }: { siteId: string; levelId: string; mode: string }) {
    return Api.get(PATH + `/${siteId}/export-level/${levelId}`, { params: { mode } });
  },

  exportData({ siteIds }: { siteIds: string[] }) {
    return Api.post(PATH + `/export`, { siteIds });
  },

  duplicateSite(id: string) {
    return Api.post(PATH + '/duplicate', { id: id });
  },

  backupSite(id: string) {
    return Api.post(PATH + '/backup', { id });
  },

  async restoreSite(id: string, zip: File) {
    const backupData = await zipToJson(zip);
    return Api.post(PATH + '/restore', { id, backupData });
  }
};
