import i18n from '../i18n';

export const getDisplayFormatDate = () => (i18n.locale === 'hu' ? 'YYYY.MM.DD.' : 'DD/MM/YYYY');
export const getDisplayFormatDateTime = () =>
  i18n.locale === 'hu' ? 'YYYY.MM.DD. HH:mm' : 'DD/MM/YYYY HH:mm';
export const getDisplayFormatTime = () => 'HH:mm';

export function preZero(number) {
  return ('0' + number).slice(-2);
}
