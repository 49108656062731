import { getField, updateField } from 'vuex-map-fields';
import constants from '~/shared/constants';
import {
  checkIntersections,
  getFromPropertyName,
  getNewUniqueId,
  getToPropertyName,
  isEdgeOrNetworkSegment
} from '~/shared/utils';

const emptyExchangePoint = {
  id: null,
  _type: constants.FEATURE_TYPES.EXCHANGE_POINT,
  type: null,
  method: null,
  _siteId: null,
  fromJunction: null,
  toJunction: null
};

const emptyState = {
  featuresByLevel: {},
  currentFeatures: [],
  selectedNetworkSegmentIds: [],
  exchangePoints: []
};

const state = {
  ...emptyState
};

const actions = {
  async updateLevel({ commit, dispatch, state }, { levelId, features, operation, skipHistory }) {
    const featureIds = features.map((feature) => feature.id);
    const deletedStairs = state.featuresByLevel[levelId].filter(
      (delFeature) =>
        !featureIds.includes(delFeature.id) &&
        delFeature.feature.properties._type === constants.FEATURE_TYPES.JUNCTION &&
        delFeature.feature.properties.type === constants.JUNCTION_TYPES.STAIRS
    );

    for (const delStairs of deletedStairs) {
      const newFeature = await dispatch(
        'stairs/delete',
        {
          stairsId: delStairs.id,
          openConfirmPopup: false
        },
        { root: true }
      );

      if (newFeature) {
        features.push(newFeature);
      }
    }

    const deletedElevators = state.featuresByLevel[levelId].filter(
      (delFeature) =>
        !featureIds.includes(delFeature.id) &&
        delFeature.feature.properties._type === constants.FEATURE_TYPES.JUNCTION &&
        delFeature.feature.properties.type === constants.JUNCTION_TYPES.ELEVATOR
    );
    for (const delElevator of deletedElevators) {
      const newFeature = await dispatch(
        'elevator/delete',
        {
          elevatorId: delElevator.id,
          calledFromContextMenu: false
        },
        { root: true }
      );

      if (newFeature) {
        features.push(newFeature);
      }
    }

    const deletedEntryPoints = state.featuresByLevel[levelId].filter(
      (delFeature) =>
        !featureIds.includes(delFeature.id) &&
        delFeature.feature.properties._type === constants.FEATURE_TYPES.JUNCTION &&
        delFeature.feature.properties.type === constants.JUNCTION_TYPES.ENTRY_POINT
    );
    for (const entryPoint of deletedEntryPoints) {
      const newFeature = await dispatch('entryPoint/delete', { entryPoint }, { root: true });

      if (newFeature) {
        features.push(newFeature);
      }
    }

    /*
      try to find reference node by coordinates as well as by id
      because after creation delFeature's id is just an integer like 1,2,3
      while featureIds are Mongo ids like 5f8b1a5b1c9d440000f3b0a0 so they can't be compared
    */
    const deletedReferenceNodes = state.featuresByLevel[levelId].filter((delFeature) => {
      const isReferenceNodeInFeatures = features.find(
        (feature) =>
          feature.feature.id === delFeature.id ||
          (feature.feature.geometry.coordinates[0] === delFeature.feature.geometry.coordinates[0] &&
            feature.feature.geometry.coordinates[1] === delFeature.feature.geometry.coordinates[1])
      );
      return (
        !isReferenceNodeInFeatures &&
        delFeature.feature.properties._type === constants.FEATURE_TYPES.REFERENCE_NODE
      );
    });
    for (let delRefNode of deletedReferenceNodes) {
      await dispatch(
        'referenceNode/delete',
        {
          coords: delRefNode.feature.geometry.coordinates
        },
        { root: true }
      );
    }

    commit('updateLevel', { levelId, features });
    if (deletedStairs.length > 0 || deletedElevators.length > 0) {
      commit('updateCurrentFeatures', levelId);
    }

    commit('checkIntersections', null);

    if (!skipHistory) {
      dispatch('history/add', operation, { root: true });
    }
  },

  loadFromHistory({ commit, rootGetters }, payload) {
    const editedLevelId = rootGetters['level/editedLevelId'];
    commit('loadFromHistory', {
      features: payload.features,
      exchangePoints: payload.exchangePoints,
      levelId: editedLevelId
    });
  },

  async deleteLevel({ commit, dispatch }, levelId) {
    // handle elevators of this level
    const elevatorsOfDeletedLevel = state.featuresByLevel[levelId].filter(
      (feature) => feature.feature.properties.type === constants.JUNCTION_TYPES.ELEVATOR
    );
    for (const elevator of elevatorsOfDeletedLevel) {
      await dispatch(
        'elevator/deleteLevel',
        { editedElevator: elevator, deletedLevelId: levelId },
        { root: true }
      );
    }

    // handle stairs of this level
    const stairsOfDeletedLevel = state.featuresByLevel[levelId].filter(
      ({ feature }) => feature.properties.type === constants.JUNCTION_TYPES.STAIRS
    );

    for (const stairs of stairsOfDeletedLevel) {
      await dispatch(
        'stairs/delete',
        { stairsId: stairs.id, openConfirmPopup: false },
        { root: true }
      );
    }

    // delete all other features
    commit('deleteLevel', levelId);
  },

  refresh({ commit, rootGetters }, payload) {
    payload.editedLevelId = rootGetters['level/editedLevelId'];
    commit('refresh', payload);
  },

  updateSelectedNetworkSegmentIds({ commit }, payload) {
    commit('updateSelectedNetworkSegmentIds', payload);
  },

  // exchange point
  addExchangePoint({ commit }, payload) {
    const newExchangePoint = Object.assign({ ...emptyExchangePoint }, payload);
    commit('addExchangePoint', newExchangePoint);
  },

  networkSegmentIsConnected({ rootGetters }, { junctionId, levelId }) {
    // checks if the incoming elevator or stairs junction is connected to the network
    const featuresByLevel = rootGetters['feature/featuresByLevel'];
    if (!featuresByLevel[levelId]) {
      return false;
    }
    const networkSegmentsOfLevel = featuresByLevel[levelId].filter(
      (feature) => feature.feature.properties._type === constants.FEATURE_TYPES.NETWORK_SEGMENT
    );
    const netSegConnected = networkSegmentsOfLevel.find(
      (networkSeg) =>
        networkSeg.feature.properties.fromJunction === junctionId ||
        networkSeg.feature.properties.toJunction === junctionId
    );

    return netSegConnected ? true : false;
  },

  addNewLevel({ commit, rootGetters, state }, newLevelId) {
    const levels = rootGetters['level/levels'];
    const streetLevel = levels.find((level) => level.order === 0);
    const referenceNodesOnStreetLevel = state.featuresByLevel[streetLevel.id].filter(
      (feature) => feature.feature.properties._type === constants.FEATURE_TYPES.REFERENCE_NODE
    );

    commit('addNewLevel', { newLevelId, referenceNodesOnStreetLevel });
  },

  editFeatureProperties({ commit }, featureProperties) {
    const object = state.featuresByLevel[featureProperties.levelId].find(
      (object) => object.id === featureProperties.id
    );
    object.feature.properties = { ...object.feature.properties, ...featureProperties };
    commit('editFeature', object);
  },

  updateCurrentFeatures({ commit }, levelId) {
    commit('updateCurrentFeatures', levelId);
  },

  reset({ commit }) {
    commit('reset');
  }
};

const mutations = {
  reset(state) {
    Object.assign(state, emptyState);
  },

  refresh(state, payload) {
    state.featuresByLevel = {};
    for (const level of payload.levels) {
      state.featuresByLevel[level.id] = payload.features
        .map((feature) => ({
          ...feature,
          feature: {
            ...feature.feature,
            properties: { ...feature.feature.properties, alreadyCreated: true }
          }
        }))
        .filter((feature) => feature.feature.properties.levelId === level.id);
    }
    state.currentFeatures = JSON.parse(
      JSON.stringify(state.featuresByLevel[payload.editedLevelId])
    );
    state.exchangePoints = [...payload.exchangePoints];
  },

  loadFromHistory(state, payload) {
    state.featuresByLevel = payload.features;
    state.currentFeatures = JSON.parse(JSON.stringify(state.featuresByLevel[payload.levelId]));
    state.exchangePoints = payload.exchangePoints;
  },

  updateCurrentFeatures(state, levelId) {
    state.currentFeatures = JSON.parse(JSON.stringify(state.featuresByLevel[levelId]));
  },

  addNewLevel(state, payload) {
    state.featuresByLevel[payload.newLevelId] = [];
    // add reference nodes of street level
    for (const refNode of payload.referenceNodesOnStreetLevel) {
      const newFeatureId = getNewUniqueId();
      let newRefNode = JSON.parse(JSON.stringify(refNode));
      newRefNode.id = newFeatureId;
      newRefNode.levelId = payload.newLevelId;
      newRefNode.feature.properties.levelId = payload.newLevelId;
      state.featuresByLevel[payload.newLevelId].push(newRefNode);
    }
  },

  duplicateLevel(state, { oldLevelId, newLevelId }) {
    state.featuresByLevel[newLevelId] = JSON.parse(
      JSON.stringify(state.featuresByLevel[oldLevelId])
    );
    let i = 0;
    let idMap = new Map();
    for (let feature of state.featuresByLevel[newLevelId]) {
      const newId = `${newLevelId}y${++i}`;
      idMap.set(feature.id, newId);
      feature.id = newId;
      feature.feature.id = newId;
      feature.levelId = newLevelId;
      feature.feature.properties.levelId = newLevelId;
      if (feature.feature.properties.id) {
        feature.feature.properties.id = newId;
      }
      if (feature.feature.properties.alreadyCreated) {
        delete feature.feature.properties.alreadyCreated;
      }

      // keep stairs' and elavators' copied junctions as normal junctions
      if (feature.feature.properties.type === constants.JUNCTION_TYPES.STAIRS) {
        feature.feature.properties.type = constants.JUNCTION_TYPES.NORMAL;
        delete feature.feature.properties.direction;
      } else if (feature.feature.properties.type === constants.JUNCTION_TYPES.ELEVATOR) {
        feature.feature.properties.type = constants.JUNCTION_TYPES.NORMAL;
      }
    }
    for (let feature of state.featuresByLevel[newLevelId].filter((_feature) =>
      isEdgeOrNetworkSegment(_feature.feature)
    )) {
      const fromProp = getFromPropertyName(feature.feature.properties._type);
      const toProp = getToPropertyName(feature.feature.properties._type);
      feature.feature.properties[fromProp] = idMap.get(feature.feature.properties[fromProp]);
      feature.feature.properties[toProp] = idMap.get(feature.feature.properties[toProp]);
    }
  },

  deleteLevel(state, levelId) {
    delete state.featuresByLevel[levelId];
  },

  updateLevel(state, { levelId, features }) {
    state.featuresByLevel = { ...state.featuresByLevel };
    state.featuresByLevel[levelId] = features.map((feature) => ({ ...feature, levelId }));
  },

  updateSelectedNetworkSegmentIds(state, payload) {
    state.selectedNetworkSegmentIds = [...payload];
  },

  // features
  addFeature(state, payload) {
    state.featuresByLevel[payload.levelId].push(payload);
  },

  deleteFeature(state, deletedFeatureId) {
    for (const levelId in state.featuresByLevel) {
      const features = state.featuresByLevel[levelId];
      if (features.find((feature) => feature.id === deletedFeatureId)) {
        state.featuresByLevel[levelId] = features.filter(
          (feature) => feature.id !== deletedFeatureId
        );
        break;
      }
    }
  },

  editFeature(state, payload) {
    for (const levelId in state.featuresByLevel) {
      const features = state.featuresByLevel[levelId];
      if (features.find((feature) => feature.id === payload.id)) {
        const editedFeatureIdx = features.findIndex((feature) => feature.id === payload.id);
        state.featuresByLevel[levelId].splice(editedFeatureIdx, 1);
        state.featuresByLevel[levelId].push(payload);
        break;
      }
    }
  },

  // exchange points
  addExchangePoint(state, payload) {
    state.exchangePoints.push(payload);
  },

  deleteExchangePoint(state, exchangePoint) {
    state.exchangePoints = state.exchangePoints.filter(
      (exchPoint) =>
        exchPoint.fromJunction !== exchangePoint.fromJunction &&
        exchPoint.toJunction !== exchangePoint.toJunction
    );
  },

  editExchangePoint(state, payload) {
    const editedExchangePointIdx = state.exchangePoints.findIndex((exchPoint) => {
      if (exchPoint._id && !exchPoint.id) {
        exchPoint.id = exchPoint._id;
      }
      if (payload._id && !payload.id) {
        payload.id = payload._id;
      }
      return exchPoint.id === payload.id;
    });
    Object.assign(state.exchangePoints[editedExchangePointIdx], payload);
  },

  checkIntersections(state) {
    const result = checkIntersections(state.featuresByLevel);
    state.featuresByLevel = result;
  },

  updateField
};

const getters = {
  featuresByLevel: (state) => state.featuresByLevel,
  currentFeatures: (state) => state.currentFeatures,
  selectedNetworkSegmentIds: (state) => state.selectedNetworkSegmentIds,
  exchangePoints: (state) => state.exchangePoints,
  allFeatures: (state) => {
    const features = [];
    for (const levelFeatures of Object.keys(state.featuresByLevel)) {
      for (const feature of state.featuresByLevel[levelFeatures]) {
        features.push(feature);
      }
    }
    return features;
  },
  getField
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
