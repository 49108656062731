import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { preZero } from './dateFormats';

/*
For using JSZip see https://stuk.github.io/jszip/
Especially How to / examples menupoint
*/

export async function jsonToZip(siteId: string, json: object) {
  const zip = new JSZip();
  const now = new Date();
  const timestamp =
    now.getFullYear() +
    preZero(now.getMonth() + 1) +
    preZero(now.getDate()) +
    '-' +
    preZero(now.getHours()) +
    preZero(now.getMinutes());
  const fileName = `${siteId}-backup-${timestamp}`;
  zip.file(`${fileName}.json`, JSON.stringify(json));
  const fileToSave = await zip.generateAsync({ type: 'blob' });
  saveAs(fileToSave, `${fileName}.zip`);
}

export async function zipToJson(uploadedFile: File) {
  try {
    const content = await JSZip.loadAsync(uploadedFile);
    const filesInZip = Object.values(content.files);
    if (filesInZip.length !== 1) {
      throw new InvalidZipFileError();
    }
    const jsonData = await content.file(filesInZip[0].name)?.async('string');
    if (!jsonData || typeof jsonData !== 'string') {
      throw new InvalidBackupDataError();
    }
    return JSON.parse(jsonData);
  } catch (error) {
    if (error instanceof InvalidZipFileError || error instanceof InvalidBackupDataError) {
      throw error;
    } else {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error('Unable to restore site from the uploaded ZIP file');
    }
  }
}

class InvalidBackupDataError extends Error {
  constructor(message?: string) {
    super(message || 'Invalid backup data in ZIP file');
  }
}

class InvalidZipFileError extends Error {
  constructor(message?: string) {
    super(message || 'Invalid backup ZIP file');
  }
}
