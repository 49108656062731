export type Endpoints = {
  from: string;
  to: string;
};

/**
 * Returns a true if every endpoint ID (representing endpoints of lines)
 * is in the given array of point IDs
 */
export function validateEdges(lineEndpointIds: Endpoints[], pointIds: string[]): boolean {
  return lineEndpointIds.every(
    (lineEndPoint) => pointIds.includes(lineEndPoint.from) && pointIds.includes(lineEndPoint.to)
  );
}
